.wrapper {
  margin-bottom: 15px;
}

.input {
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 5px 12px;
  border: 1px solid var(--grey30);
  border-radius: 8px;
  background-color: transparent;
  color: var(--grey70);
  font-size: 14px;
  overflow: visible;
  outline: none;
}

.inputSuccess {
  border-color: var(--green60);
  color: var(--green60);
}

.inputError {
  border-color: var(--red60);
  color: var(--red60);
}

.inputDisabled {
  border-color: transparent;
  background-color: var(--grey10);
  color: var(--grey30);
}

.inputHelp {
  margin-bottom: 7px;
}

.input:hover {
  border: 1px solid var(--primary30);
}

.inputDisabled:hover {
  border-color: transparent;
}

.input:focus {
  border: 1px solid var(--primary60);
}

.subtitleError {
  color: var(--red60);
}
