.container {
  display: grid;
  grid-template-rows: [main-top] 1fr [main-bottom];
  grid-template-areas: 'main';
  min-height: 100vh;
  margin: 0 auto;
}

.content {
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .content {
    padding: 0 10px;
    font-size: 0.7rem;
  }
}
