.alert {
  margin: 10px auto;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  color: var(--grey100);
}

.alertInfo {
  border-color: var(--green30);
}

.alertError {
  border-color: var(--red30);
  color: var(--red60);
}
