.loader {
  --color: radial-gradient(farthest-side, var(--color-accent) 92%, transparent);

  width: 50px;
  height: 50px;
  background: var(--color) 50% 0, var(--color) 50% 100%, var(--color) 100% 50%, var(--color) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: loader-animation 1s infinite;
}

@keyframes loader-animation {
  100% {
    transform: rotate(0.5turn);
  }
}
