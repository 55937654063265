.container {
  display: grid;
  grid-template-rows: [header-top] auto [header-bottom main-top] 1fr [main-bottom];
  grid-template-areas:
    'header'
    'main';
  max-width: 1320px;
  min-width: 320px;
  min-height: 100vh;
  margin: 0 auto;
}

.header {
  grid-area: header;
  background-color: var(--grey30);
}

.content {
  display: grid;
  grid-area: main;
  margin-top: 20px;
  padding: 0 10px;
}

@media screen and (min-width: 450px) {
  .content {
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) {
  .content {
    padding: 0 40px;
  }
}
