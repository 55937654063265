.navigation {
  padding: 0 20px;
}

.menuList {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.menuLink,
.menuLink:visited {
  display: block;
  padding: 8px 0;
  color: var(--grey100);
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.menuLink:hover {
  color: var(--grey70);
}

.menuLinkActive,
.menuLinkActive:visited,
.menuLinkActive:hover {
  color: var(--grolli-violet);
}

@media screen and (min-width: 875px) {
  .menuList {
    flex-direction: row;
  }

  .menuLink {
    padding: 8px 16px;
  }
}
