@font-face {
  font-family: Stolzl;
  src: url('./fonts/Stolzl-Light.eot');
  src: local('Stolzl Light'), local('Stolzl-Light'),
    url('./fonts/Stolzl-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Stolzl-Light.woff2') format('woff2'),
    url('./fonts/Stolzl-Light.woff') format('woff'),
    url('./fonts/Stolzl-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Stolzl;
  src: url('./fonts/Stolzl-Thin.eot');
  src: local('Stolzl Thin'), local('Stolzl-Thin'),
    url('./fonts/Stolzl-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Stolzl-Thin.woff2') format('woff2'), url('./fonts/Stolzl-Thin.woff') format('woff'),
    url('./fonts/Stolzl-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl Book';
  src: url('./fonts/Stolzl-Book.eot');
  src: local('Stolzl Book'), local('Stolzl-Book'),
    url('./fonts/Stolzl-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Stolzl-Book.woff2') format('woff2'), url('./fonts/Stolzl-Book.woff') format('woff'),
    url('./fonts/Stolzl-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Stolzl;
  src: url('./fonts/Stolzl-Regular.eot');
  src: local('Stolzl Regular'), local('Stolzl-Regular'),
    url('./fonts/Stolzl-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Stolzl-Regular.woff2') format('woff2'),
    url('./fonts/Stolzl-Regular.woff') format('woff'),
    url('./fonts/Stolzl-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Stolzl;
  src: url('./fonts/Stolzl-Bold.eot');
  src: local('Stolzl Bold'), local('Stolzl-Bold'),
    url('./fonts/Stolzl-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Stolzl-Bold.woff2') format('woff2'), url('./fonts/Stolzl-Bold.woff') format('woff'),
    url('./fonts/Stolzl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Stolzl;
  src: url('./fonts/Stolzl-Medium.eot');
  src: local('Stolzl Medium'), local('Stolzl-Medium'),
    url('./fonts/Stolzl-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Stolzl-Medium.woff2') format('woff2'),
    url('./fonts/Stolzl-Medium.woff') format('woff'),
    url('./fonts/Stolzl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
