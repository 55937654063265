.codeBox {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--grey70);
  color: #ecfdfe;
  overflow-wrap: break-word;
}

.code {
  color: var(--grey0);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
