.body {
  background-color: #04050e;
  color: #e3e4e8;
}

.container {
  display: grid;
  grid-template-rows: [main-top] 1fr [main-bottom];
  grid-template-areas: 'main';
  align-items: center;
  max-width: 1320px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 0;
}

.content {
  grid-area: main;
}

.logoWrapper {
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.logo {
  fill: white;
}

.navigation {
  padding: 0 20px;
}

.menuList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  text-align: center;
}

.menuLink,
.menuLink:visited {
  display: block;
  padding: 8px 0;
  color: var(--color-accent);
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.menuLink:hover {
  color: var(--grey5);
}

.appLinksContainer {
  display: flex;
  justify-content: center;
  gap: 35px;
  margin-top: 80px;
}

.appLinkContainer {
  width: 165px;
}

.appLinkImage {
  width: 100%;
  fill: inherit;
}

.appLinkImageAppStore {
  width: 88%;
  margin: 0 0 0 6%;
}

@media screen and (min-width: 530px) {
  .menuList {
    flex-direction: row;
  }

  .menuLink {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 450px) {
  .logoWrapper {
    width: 90%;
  }

  .appLinksContainer {
    flex-direction: column;
    align-items: center;
  }

  .appLinkImageAppStore {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 450px) and (max-width: 768px) {
  .content {
    padding: 0 10px;
  }

  .logoWrapper {
    width: 85%;
  }
}
