.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notFoundCode {
  display: inline-block;
  margin: 0;
  margin-right: 22px;
  padding-right: 22px;
  border-right: 1px solid rgb(0 0 0 / 30%);
  font-weight: 500;
}

.notFoundMessage {
  font-weight: 300;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .content {
    padding: 0 10px;
    font-size: 0.7rem;
  }

  .notFoundCode {
    margin-right: 16px;
    padding-right: 16px;
  }
}
