:root {
  /* base colors */
  --grey0: #f9f9fa;
  --grey5: #f0f0f1;
  --grey10: #e1e1e3;
  --grey30: #b4b4bb;
  --grey60: #5a5b6a;
  --grey70: #4a4b57;
  --grey100: #19191d;
  --blue30: #9dc2ff;
  --green30: #a9d3ab;
  --green60: #43a047;
  --red30: #faa9a3;
  --red60: #f44336;
  --primary30: #f6a8c2;
  --primary60: #ec407a;

  /* brand colors */
  --grolli-red: #dc2153;
  --grolli-red-dark: #b11b43;
  --grolli-violet: #5e20bd;
  --grolli-violet-dark: #4d1a99;

  /* colors */
  --color-body-background: var(--grey5);
  --color-accent: var(--grolli-red);

  /* gradients */
  --gradient-accent: linear-gradient(100deg, var(--grolli-red), var(--grolli-violet));
  --gradient-accent-dark: linear-gradient(
    100deg,
    var(--grolli-red-dark),
    var(--grolli-violet-dark)
  );
}
