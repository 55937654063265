.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--grey10);
  border-radius: 8px;
  background: white;
}
