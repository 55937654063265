@import './variables.css';
@import './fonts.css';

body {
  background: var(--color-body-background);
  font-family: Stolzl, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto Mono', Menlo, Consolas, Monaco, 'Lucida Console', 'Liberation Mono',
    'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace;
}

a,
a:visited {
  color: var(--color-accent);
  text-decoration: none;
}

a:hover {
  color: var(--grolli-red-dark);
}
