.button {
  width: 100%;
  margin: 7px 0;
  padding: 8px 12px;
  border-radius: 8px;
  background: var(--gradient-accent);
  color: var(--grey0);
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.buttonBig {
  line-height: 30px;
}

.button:hover {
  color: var(--grey5);
  opacity: 0.95;
}

.button:focus {
  border: 1px solid var(--blue30);
  outline: none;
  opacity: 1;
}

.button:active {
  background: var(--gradient-accent-dark);
  color: var(--grey5);
  outline: none;
  opacity: 1;
}

.button:disabled,
.button:disabled:hover {
  background: var(--grey10);
  color: var(--grey30);
  opacity: 1;
  cursor: not-allowed;
}
