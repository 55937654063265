.wrapper {
  margin-bottom: 15px;
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.label {
  position: relative;
  display: flex;
  padding-left: 32px;
}

.label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid var(--grey30);
  border-radius: 3px;
}

.label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 1em;
  height: 1em;
  background: url('../../icons/checkedIcon.svg');
  transform: scale(0);
  transition: all 0.15s;
}

.input:checked + .label::after {
  transform: scale(1);
}

.input:disabled + .label {
  opacity: 0.3;
}

.input:disabled + .label::before {
  border-color: var(--grey30);
}

.input:disabled + .label::after {
  opacity: 0.3;
}

.title {
  margin-bottom: 6px;
  color: var(--grey70);
}

.subtitle {
  margin-top: 7px;
}

.subtitleError {
  color: var(--red60);
}
